<script>
	// Aquí puedes agregar cualquier lógica adicional si es necesario
	import WaveText from "./WaveText.svelte";
  </script>
  
  <style>
	:global(body) {
	  margin: 0;
	  padding: 0;
	  font-family: 'Arial', sans-serif;
	  background-color: #282c34;
	}
  
	.container {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  height: 100vh;
	  background-image: url('/foto.jpg'); /* Cambia esto por la ruta de tu imagen */
	  background-size: cover;
	  background-position: center;
	  color: white;
	  text-align: center;
	  flex-direction: column;
	  padding: 0 20px;
	}
  
	h1 {
	  font-size: 3em;
	  margin: 0;
	}
	/* h3 {
	  font-size: 1em;
	  font-family:serif;
	  margin: 0;
	  color:aquamarine;
	} */
  
	p {
	  font-size: 1.5em;
	  margin: 0;
	  margin-top: 10px;
	}
  
	.highlight {
	  color: #61dafb;
	}
  
	.message {
	  background: rgba(0, 0, 0, 0.5);
	  padding: 20px;
	  border-radius: 10px;
	}
  </style>
  
  <div class="container">
	<WaveText text="MUSSA de MAR" />
	<br>
	<div class="message">
	<!-- 	<h3>MUSSA de MAR</h3> -->
		
	  <h1>¡Estamos en construcción!</h1>
	  <p>Volveremos pronto con algo <span class="highlight">increíble</span>.</p>
	</div>
  </div>
  