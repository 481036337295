<script>
    export let text = "Svelte Waves";
  </script>
  
  <style>
    .wave-text {
      display: inline-block;
      font-size: 1.5em;
      font-family:serif;
      font-weight: bold;
      overflow: visible; /* Asegúrate de que el contenido no se corte */
      position: relative;
      white-space: nowrap;
      color: #0077b6; /* Color del texto */
    }
  
    .wave-text span {
      display: inline-block;
      position: relative;
      animation: wave-animation 2s infinite ease-in-out;
      animation-delay: calc(0.1s * var(--i));
    }
  
    @keyframes wave-animation {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-5px); /* Ajusta la altura de la ola */
      }
    }
  </style>
  
  <div class="wave-text">
    {#each text.split('') as char, i}
      <span style="--i: {i}">{char}</span>
    {/each}
  </div>
  
  